<template>
  <v-card :loading="Object.keys(data).length == 0">
    <v-container v-if="Object.keys(data).length > 0" fluid>
      <div class="d-flex align-center flex-wrap mb-4" style="gap: 16px">
        <v-alert class="ma-0" v-if="!data.CESCE" type="warning" text
          >Este cliente no está en CESCE</v-alert
        >

        <template v-else-if="data.CESCE">
          <v-alert class="ma-0" type="success" text
            >Este cliente está en CESCE</v-alert
          >
          <v-alert class="ma-0" type="info" text
            >Crédito solicitado:
            <b>{{ data.creditLimitRequested }}€</b></v-alert
          >
          <v-alert class="ma-0" type="info" text
            >Crédito concedido: <b>{{ data.creditLimitGranted }}€</b></v-alert
          >
          <v-alert class="ma-0" type="info" text
            >Cobertura Riesgo Comercial:
            <b>{{ data.commercialRiskCoverPct }}%</b></v-alert
          >
        </template>

        <v-spacer />

        <v-alert
          class="ma-0"
          :type="data.CESCE_siniestro ? 'success' : 'error'"
          text
          >Siniestro en CESCE:
          <b>{{ data.CESCE_siniestro ? "Sí" : "No" }}</b></v-alert
        >
        <v-btn @click.stop="siniestroCesce" color="info darken-1" large>
          Siniestro en CESCE
        </v-btn>
      </div>

      <v-row
        v-if="$root.acceso('DEV') && !Number(data.CESCE)"
        class="align-center"
      >
        <v-col cols="12" sm="3">
          <v-text-field
            label="Solicitud Crédito €"
            filled
            dense
            hide-details="auto"
            :rules="[rules.req]"
            v-model="creditoSolicitado"
          />
        </v-col>
        <v-col cols="auto">
          <v-btn
            disabled
            :loading="sending"
            @click.stop="enviarCesce"
            outlined
            large
            color="secondary"
          >
            <v-icon left>mdi-cog</v-icon>Enviar a CESCE
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="Number(data.CESCE)">
        <v-col cols="12" md="6">
          <tarjeta-datos
            titulo="Datos de CESCE"
            icon="cog"
            :datos="[
              { nombre: 'address', valor: data.address },
              { nombre: 'cancellationDate', valor: data.cancellationDate },
              { nombre: 'cesceCode', valor: data.cesceCode },
              { nombre: 'city', valor: data.city },
              { nombre: 'classificationDate', valor: data.classificationDate },
              {
                nombre: 'classificationDecisionCode',
                valor: data.classificationDecisionCode,
              },
              { nombre: 'commentsInd', valor: data.commentsInd },
              {
                nombre: 'commercialRiskCoverPct',
                valor: data.commercialRiskCoverPct,
              },
              {
                nombre: 'commercialRiskGroupCode',
                valor: data.commercialRiskGroupCode,
              },
              { nombre: 'companyName', valor: data.companyName },
              { nombre: 'countryCode', valor: data.countryCode },
              { nombre: 'creditLimitGranted', valor: data.creditLimitGranted },
              {
                nombre: 'creditLimitRequested',
                valor: data.creditLimitRequested,
              },
              { nombre: 'currencyCode', valor: data.currencyCode },
              { nombre: 'customerReference', valor: data.customerReference },
              { nombre: 'effectiveDate', valor: data.effectiveDate },
              { nombre: 'email', valor: data.email },
              { nombre: 'endorsementNo', valor: data.endorsementNo },
              {
                nombre: 'paymentMethodGrantedCode',
                valor: data.paymentMethodGrantedCode,
              },
              {
                nombre: 'paymentMethodRequestedCode',
                valor: data.paymentMethodRequestedCode,
              },
              {
                nombre: 'paymentTermsGranted',
                valor: data.paymentTermsGranted,
              },
              {
                nombre: 'paymentTermsRequested',
                valor: data.paymentTermsRequested,
              },
              { nombre: 'phoneNo', valor: data.phoneNo },
              {
                nombre: 'politicalRiskCoverPct',
                valor: data.politicalRiskCoverPct,
              },
              {
                nombre: 'politicalRiskGroupCode',
                valor: data.politicalRiskGroupCode,
              },
              { nombre: 'postalCode', valor: data.postalCode },
              { nombre: 'requestEntryDate', valor: data.requestEntryDate },
              { nombre: 'state', valor: data.state },
              { nombre: 'statusCode', valor: data.statusCode },
              { nombre: 'taxCode', valor: data.taxCode },
              { nombre: 'validityDate', valor: data.validityDate },
              { nombre: 'validityReasonCode', valor: data.validityReasonCode },
            ]"
          >
          </tarjeta-datos>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { parseImpagados } from "../services/parseImpagados.js";
import { req } from "@/utils/validations.js";

export default {
  data() {
    return {
      rules: { req },
      creditoSolicitado: null,
      data: {},
      sending: false,
    };
  },
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
  },
  props: {
    IdCarteraCobro: String | Number,
  },
  methods: {
    enviarCesce() {
      this.sending = true;
      let str = this.$store.getters.getDatosEmpresa;
      const { IdAgente } = str.iss;

      axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/cesce/pedirCredito.php`,
        method: "POST",
        data: {
          token: this.$store.getters.getJwtEmpresa,
          IdCarteraCobro: this.IdCarteraCobro,
          IdAgente,
        },
      })
        .then(({ data }) => {
          if (JSON.parse(data).httpCode >= 400) throw new Error(data);
          this.$root.$emit("snack", "Enviado a CESCE");
        })
        .catch((err) => {
          console.error(err);
          this.$root.$emit("snack", "No se ha podido enviar a CESCE");
        })
        .finally(() => (this.sending = false));
    },
    async siniestroCesce() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_OUR_API_URL}/cesce/siniestroCesce.php`,
        data: {
          IdCarteraCobro: this.IdCarteraCobro,
          token: this.$store.getters.getJwtEmpresa,
        },
      });
      this.getDatos();
    },
    getDatos() {
      axios({
        url: `${process.env.VUE_APP_OUR_API_URL}/cesce/getImpagoCesce.php`,
        method: "GET",
        params: {
          token: this.$store.getters.getJwtEmpresa,
          IdCarteraCobro: this.IdCarteraCobro,
        },
      }).then(({ data }) => {
        this.data = parseImpagados([data.Impagado])[0];
        this.data = { ...this.data, ...data.CESCE };
      });
    },
  },
  mounted() {
    this.getDatos();
  },
};
</script>
